import React from 'react'
import classNames from 'classnames'
import {DH} from '../../../constants/data-hooks'
import {ClosedRegistrationButton} from '../../registration-buttons/closed-registration-button'
import {EditRegistrationButton} from '../../registration-buttons/edit-registration-button'
import {RegistrationButtonSkin} from '../../registration-buttons/registration-button-skin'
import {TicketsUnavailableButton} from '../../registration-buttons/tickets-unavailable-button'
import s from './registration-button.scss'
import {RegistrationButtonProps} from './index'

export const RegistrationButton: React.FC<RegistrationButtonProps> = ({
  ticketedEvent,
  registrationButtonVisible,
  registrationClosed,
  memberRsvpExists,
  rsvpButtonText,
  buttonStyle,
  onClick,
  imageVisible,
  navigateToMainPage,
  secondary,
  isMobile,
  ticketsUnavailable,
  className,
}) => {
  const getButton = () => {
    const button = (
      <RegistrationButtonSkin
        buttonStyle={buttonStyle}
        text={rsvpButtonText}
        onClick={onClick}
        dataHook={ticketedEvent ? DH.HEADER_GET_TICKETS_BUTTON : DH.RSVP_BUTTON}
        secondary={secondary}
      />
    )

    if (!isMobile && secondary && (ticketedEvent || !registrationButtonVisible)) {
      return null
    }

    if (ticketsUnavailable) {
      return <TicketsUnavailableButton onClick={navigateToMainPage} />
    }

    if (registrationClosed) {
      return <ClosedRegistrationButton onClick={navigateToMainPage} />
    }

    if (memberRsvpExists) {
      return <EditRegistrationButton />
    }

    if (!registrationButtonVisible) {
      return isMobile && ticketedEvent && secondary ? button : null
    }

    return button
  }

  return (
    <div
      className={classNames(
        isMobile || imageVisible ? s.registrationButtonSpacing : s.registrationButtonSpacingNoImage,
        {
          [s.secondaryButtonSpacing]: secondary,
        },
        className,
      )}
    >
      {getButton()}
    </div>
  )
}
