import classNames from 'classnames'
import React from 'react'
import XIcon from 'wix-ui-icons-common/X'
import ConfirmIcon from 'wix-ui-icons-common/Confirm'
import TagIcon from 'wix-ui-icons-common/Tag'
import {TFunction} from 'i18next'
import {DH} from '../../../constants/data-hooks'
import fs from '../../form/form.scss'
import {CouponError} from '../coupon-error'
import c from '../../classnames.scss'
import {Spinner} from '../../spinner'
import s from './coupon-input.scss'
import {CouponInputProps, CouponInputState} from '.'

const borderClass = c.formSummaryBox
const colorClass = c.formSummaryColor
const fontClass = c.formSummaryBody
const inputClass = c.formSummaryCouponInput
const cancelClass = c.formSummaryCouponCancel

export class CouponInput extends React.Component<CouponInputProps, CouponInputState> {
  state = {shouldInfoTextShow: !this.props.couponCode}
  inputRef = React.createRef<HTMLInputElement>()

  submit = () => {
    const {couponCode, eventId, reservationId, applyCoupon} = this.props
    if (couponCode.length) {
      applyCoupon(eventId, reservationId, couponCode)
    }
  }

  reset = (focus: boolean) => {
    this.setState({shouldInfoTextShow: !focus})
    this.props.resetCouponCode()
  }

  setCode = (code: string) => this.props.setCouponCode(code)

  handleOnBlur = () => {
    const {discountValid} = this.props
    if (!discountValid) {
      this.submit()
    }
    this.handleInfoText()
  }

  handleInfoText = () => {
    if (this.props.couponCode) {
      return this.setState({shouldInfoTextShow: false})
    }

    this.setState({shouldInfoTextShow: !this.state.shouldInfoTextShow})
  }

  renderClearButton = (t: TFunction) => (
    <button
      type="button"
      aria-label={t('a11y.clearCouponCodeButton')}
      data-hook={DH.COUPON_CODE_CLEAR}
      className={classNames(s.couponCancel, cancelClass)}
      onClick={() => this.reset(false)}
    >
      <XIcon className={s.couponCancelIcon} size="17px" />
    </button>
  )

  renderSpinner = () => {
    return (
      <div data-hook={DH.COUPON_CHECKING_VALIDITY} className={classNames(s.couponValidating)}>
        <Spinner diameter={17} />
      </div>
    )
  }

  render() {
    const {couponCode, discountValid, orderCompleted, showCouponInput, t, error, validating} = this.props
    const StatusIcon = discountValid ? ConfirmIcon : TagIcon
    const isError = Boolean(error)
    if (!showCouponInput) {
      return null
    }

    return (
      <div className={classNames({[fs.summaryBlock]: !isError})}>
        <div className={classNames(s.couponInputContainer, colorClass)}>
          <div className={classNames(s.couponInfoContainer)}>
            <StatusIcon size="24px" />
            {this.state.shouldInfoTextShow && (
              <span className={classNames(s.couponOpenButtonText, fontClass)} data-hook={DH.COUPON_INPUT_INFO}>
                {t('summary.enterCouponCode')}
              </span>
            )}
          </div>
          <div
            data-hook={DH.COUPON_FORM}
            className={classNames(borderClass, {
              [c.formSummaryBoxBorderColorActive]: !!couponCode?.length,
              [s.couponInputReadOnly]: orderCompleted,
            })}
          >
            <input
              type="text"
              aria-label={t('a11y.couponCodeInputField')}
              maxLength={20}
              onFocus={() => (isError ? this.reset(true) : this.handleInfoText())}
              onBlur={this.handleOnBlur}
              onChange={event => this.setCode(event.target.value)}
              onKeyPress={event => event.key === 'Enter' && this.inputRef.current.blur()}
              data-hook={DH.COUPON_CODE_INPUT}
              className={classNames(s.couponInput, inputClass)}
              value={couponCode}
              readOnly={discountValid || isError}
              ref={this.inputRef}
            />
            {((discountValid && !orderCompleted) || isError) && this.renderClearButton(t)}
            {validating && this.renderSpinner()}
          </div>
          {isError && <CouponError error={error} t={t} />}
        </div>
      </div>
    )
  }
}
