import React from 'react'
import classNames from 'classnames'
import Location from 'wix-ui-icons-common/Location'
import {Subtitle} from '../../subtitle'
import {TableRow} from '../../table-row'
import c from '../../classnames.scss'
import {ScheduleButton} from '../../styled-components/schedule-button'
import {DH} from '../../../constants/data-hooks'
import {BrowserOnly} from '../../../../commons/components/browser-only'
import {Tags} from '../../styled-components/tags'
import s from './schedule.scss'
import {ScheduleProps} from '.'

export const Schedule: React.FC<ScheduleProps> = ({
  hollowButton,
  t,
  mobile,
  items,
  total,
  navigateToSchedulePage,
  scheduleTitleText,
  roundedButton,
}) => {
  const itemsLeft = total - 2
  return (
    <div className={!mobile ? s.container : ''} data-hook={DH.SCHEDULE}>
      <Subtitle dataHook={DH.SCHEDULE_SUBTITLE} text={scheduleTitleText} />
      <ul>
        {items.slice(0, 2).map((item, index) => (
          <li>
            <TableRow
              config={{
                itemKey: `scheduleItem-${index}`,
                addDivider: !mobile || index !== 1,
                className: mobile ? s.mobileRow : s.desktopRow,
                dividerClassName: classNames(s.divider, c.evTicketDividerSize, c.evTicketDividerColor),
                columns: [
                  {
                    className: s.nameColumn,
                    content: (
                      <div>
                        <span className={classNames(s.scheduleTime, c.scheduleTimeFont, c.scheduleTimeColor)}>
                          <BrowserOnly>
                            <time dateTime={item.formattedTime}>{item.formattedTime}</time>
                          </BrowserOnly>
                        </span>
                        <br />
                        <span
                          className={classNames(s.scheduleDuration, c.scheduleDurationFont, c.scheduleDurationColor)}
                        >
                          {item.formattedDuration}
                        </span>
                      </div>
                    ),
                  },
                  {
                    className: s.descriptionColumn,
                    contentClassName: s.descriptionContent,
                    content: (
                      <div>
                        <span className={classNames(s.scheduleTitle, c.scheduleTitleFont, c.scheduleTitleColor)}>
                          {item.name}
                        </span>
                        {item.stageName && (
                          <div className={classNames(s.location, c.scheduleLocationFont, c.scheduleLocationColor)}>
                            <Location /> {item.stageName}
                          </div>
                        )}
                        {item.tags && (
                          <Tags
                            className={s.tags}
                            items={item.tags?.map(tag => ({
                              title: tag,
                              value: tag,
                            }))}
                          />
                        )}
                      </div>
                    ),
                  },
                ],
              }}
            />
          </li>
        ))}
      </ul>
      <div className={mobile ? s.mobileFooter : s.footer}>
        {itemsLeft >= 1 && (
          <div className={classNames(s.moreItemsText, c.scheduleLocationFont, c.scheduleLocationColor)}>
            {itemsLeft > 1
              ? t('schedule_moreItemsAvailable_plural', {amount: itemsLeft})
              : t('schedule_moreItemsAvailable_singular')}
          </div>
        )}
        <ScheduleButton
          mobile={mobile}
          t={t}
          hollowButton={hollowButton}
          roundedButton={roundedButton}
          onClick={() => navigateToSchedulePage()}
        />
      </div>
      {mobile && <div className={classNames(s.divider, c.evTicketDividerSize, c.evTicketDividerColor)} />}
    </div>
  )
}
