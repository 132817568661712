import {handleKeyPressEnterOrSpace} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import cn from '../../../classnames.scss'
import {namesToClasses} from '../../../names-to-classes'
import s from './add-to-calendar.scss'
import {AddToCalendarProps} from '.'

export class AddToCalendar extends React.Component<AddToCalendarProps, {optionsVisible: boolean}> {
  state = {optionsVisible: false}

  onAddCalendarClick = () => {
    this.setState({optionsVisible: !this.state.optionsVisible})
    this.props.onClick()
  }

  render() {
    const {visible, buttonText, className = '', dataHook = ''} = this.props
    const {optionsVisible} = this.state

    if (!visible) {
      return null
    }

    return (
      <div className={className} data-hook={dataHook}>
        <button
          className={this.getAddToCalendarButtonClasses()}
          data-hook="add-to-calendar"
          aria-expanded={optionsVisible}
          onClick={this.onAddCalendarClick}
        >
          {buttonText}
        </button>
        {optionsVisible ? this.getCalendarOptions() : null}
      </div>
    )
  }

  private getAddToCalendarButtonClasses() {
    const {formButtonStyle} = this.props
    const round = formButtonStyle === 2 || formButtonStyle === 4
    const roundClassName = `formButton${round ? 'Rounded' : ''}BorderRadius`
    const hollowButtonClasses = `formHollowButtonFont formHollowButtonColor formButtonBorder`
    const fullButtonClasses = `formFullButtonFont formFullButtonColor formButtonBackgroundColor`
    const buttonClasses = namesToClasses(
      `${[1, 2].includes(formButtonStyle) ? hollowButtonClasses : fullButtonClasses} ${roundClassName}`,
    )
    const spacingClass = this.state.optionsVisible ? s.optionsSpacing : undefined

    return classNames(s.addToCalendar, buttonClasses, spacingClass)
  }

  private getCalendarOptionClasses() {
    return `${s.calendarOption} ${s.fadeInUp}`
  }

  private getCalendarOptions() {
    const {calendarLinks, t} = this.props

    return (
      <div className={classNames(s.calendarOptions, cn.formTitleColor, cn.formLabelsFont)}>
        <a
          className={this.getCalendarOptionClasses()}
          data-hook="calendar-gmail"
          tabIndex={0}
          aria-label={t('accessibility:a11y.calendarButtonLabel', {
            button: 'gMail',
          })}
          onClick={() => window.open(calendarLinks.google)}
          onKeyPress={handleKeyPressEnterOrSpace(() => window.open(calendarLinks.google))}
        >
          Gmail
        </a>
        <a
          className={`${this.getCalendarOptionClasses()} ${s.delayAnimationHalf}`}
          data-hook="calendar-outlook"
          tabIndex={0}
          aria-label={t('accessibility:a11y.calendarButtonLabel', {
            button: 'Outlook',
          })}
          onClick={() => window.open(calendarLinks.ics)}
          onKeyPress={handleKeyPressEnterOrSpace(() => window.open(calendarLinks.ics))}
        >
          Outlook
        </a>
        <a
          className={`${this.getCalendarOptionClasses()} ${s.delayAnimationOne}`}
          data-hook="calendar-ical"
          tabIndex={0}
          aria-label={t('accessibility:a11y.calendarButtonLabel', {
            button: 'iCal',
          })}
          onClick={() => window.open(calendarLinks.ics)}
          onKeyPress={handleKeyPressEnterOrSpace(() => window.open(calendarLinks.ics))}
        >
          iCal
        </a>
      </div>
    )
  }
}
