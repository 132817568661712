import {withLeanStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withLeanStyles'
import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary'
import {ErrorPage} from '../../../commons/components/error-page'
import {getWidgetSentryConfig} from '../../utils/sentry'
import {App} from './app'

export default {
  component: withSentryErrorBoundary(
    withLeanStyles(App, {
      cssPath: ['eventsDetailsPage.min.css', 'eventsDetailsPage.stylable.bundle.css'],
      enableMemoization: true,
    }),
    getWidgetSentryConfig('events-details-page-viewer'),
    withLeanStyles(ErrorPage, {cssPath: ['eventDetailsPage.min.css'], enableMemoization: true}),
  ),
}
