import React from 'react'
import {BrowserOnly} from '../../../../commons/components/browser-only'
import {useEventDateInformation} from '../../../../commons/hooks/dates'
import s from './full-date.scss'
import {FullDateProps} from './index'

export const FullDate: React.FC<FullDateProps> = ({event}) => {
  const {fullDate} = useEventDateInformation(event.id)

  return (
    <div data-hook="event-full-date" className={s.dateContainer}>
      <BrowserOnly>{fullDate}</BrowserOnly>
    </div>
  )
}
