import classNames from 'classnames'
import React from 'react'
import {getImageStyle, SIDE_BY_SIDE_HEADER_IMAGE_SIZES} from '../../../../../../commons/utils/image'
import {DH} from '../../../../../constants/data-hooks'
import s from './image.scss'
import {ImageProps} from './interfaces'

export const Image: React.FC<ImageProps> = ({mainImage, imagePosition, imageOpacity, crop, fullWidth}) => {
  const imageSize = fullWidth ? SIDE_BY_SIDE_HEADER_IMAGE_SIZES.FULL_WIDTH : SIDE_BY_SIDE_HEADER_IMAGE_SIZES.MINIMIZED
  const container = crop
    ? {height: imageSize, width: imageSize}
    : {height: mainImage.height || imageSize, width: mainImage.width || imageSize}

  return (
    <div className={classNames(s.imageContainer, {[s.minimized]: !fullWidth})}>
      <div className={s.imageBackground} />
      <div
        className={s.image}
        data-hook={DH.eventImage}
        style={getImageStyle({
          image: mainImage,
          container,
          opacity: imageOpacity,
          position: imagePosition,
          superUpscaled: true,
        })}
      />
    </div>
  )
}
