import classNames from 'classnames'
import React from 'react'
import {EventDetailsButtonStyle} from '@wix/wix-events-commons-statics'
import {isFullButton, isHollowButton, isRoundedButton} from '../../../selectors/settings'
import cl from '../../classnames.scss'
import s from './registration-button-skin.scss'

export interface RsvpButtonStyleProps {
  onClick: any
  text: string
  buttonStyle: EventDetailsButtonStyle
  dataHook?: string
  secondary?: boolean
  disabled?: boolean
  minWidth?: string
  minHeight?: string
}

const getButtonClasses = (buttonStyle: EventDetailsButtonStyle, secondary: boolean, disabled: boolean) => {
  const classes = [s.registrationButton]

  if (isHollowButton(buttonStyle)) {
    const colorClass = secondary ? cl.secondRsvpHollowButtonColor : cl.rsvpHollowButtonColor
    const borderClass = secondary ? cl.secondRsvpButtonBorder : cl.rsvpButtonBorder
    classes.push(cl.rsvpHollowButtonFont, colorClass, borderClass)
  }

  if (isFullButton(buttonStyle)) {
    const colorClass = secondary ? cl.secondRsvpButtonColor : cl.rsvpButtonColor
    const backgroundClass = secondary ? cl.secondRsvpButtonBackgroundColor : cl.rsvpButtonBackgroundColor
    classes.push(cl.rsvpButtonFont, colorClass, backgroundClass)
  }

  const radiusClass = isRoundedButton(buttonStyle) ? cl.rsvpButtonRoundedBorderRadius : cl.rsvpButtonBorderRadius
  const disabledClass = isRoundedButton(buttonStyle) ? cl.rsvpButtonRoundedDisabled : cl.rsvpButtonDisabled
  classes.push(radiusClass, disabledClass)

  if (disabled) {
    classes.push(s.disabled)
  }

  return classNames(...classes)
}

export const RegistrationButtonSkin = ({
  onClick,
  text,
  dataHook,
  buttonStyle,
  secondary,
  disabled,
  minWidth,
  minHeight,
}: RsvpButtonStyleProps) => (
  <button
    data-hook={dataHook}
    className={getButtonClasses(buttonStyle, secondary, disabled)}
    onClick={onClick}
    disabled={disabled}
    style={{minWidth, minHeight}}
  >
    {text}
  </button>
)
