import {getEventDescription} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {isDescriptionVisible} from '../../../../selectors/event'
import {connect} from '../../../runtime-context/connect'
import {isFullWidth} from '../../../../../commons/selectors/environment'
import {isSideBySideHeaderLayout} from '../../../../selectors/settings'
import {Description as DescriptionPresentation} from './description'

export interface DescriptionOwnProps {}
export interface DescriptionRuntimeProps {
  description: string
  descriptionVisible: boolean
  fullWidth: boolean
  isSideBySideHeaderLayout: boolean
}
export interface DescriptionProps extends DescriptionOwnProps, DescriptionRuntimeProps {}

const mapRuntime = ({state, host}: DetailsPageAppProps): DescriptionRuntimeProps => ({
  description: getEventDescription(state.event),
  descriptionVisible: isDescriptionVisible(state),
  fullWidth: isFullWidth(state, host.dimensions),
  isSideBySideHeaderLayout: isSideBySideHeaderLayout(state.component.settings),
})

export const Description = connect<DescriptionOwnProps, DescriptionRuntimeProps>(mapRuntime)(DescriptionPresentation)
