import React from 'react'
import {EventDetailsImageRatio} from '@wix/wix-events-commons-statics'
import {getImageStyle} from '../../../../commons/utils/image'
import {DH} from '../../../constants/data-hooks'
import c from '../../classnames.scss'
import s from './mobile-image.scss'
import {MobileImageProps} from '.'

const IMAGE_SIZE = {
  [EventDetailsImageRatio['1:1']]: {
    width: 320,
    height: 320,
  },
  [EventDetailsImageRatio['1:2']]: {
    width: 320,
    height: 160,
  },
}

export const MobileImage: React.FC<MobileImageProps> = ({mainImage, imagePosition, imageOpacity, imageRatio}) => {
  const container = IMAGE_SIZE[imageRatio]

  return (
    <div className={s.container} style={{height: container.height}}>
      <div
        className={s.mobileImage}
        data-hook={DH.eventImage}
        style={getImageStyle({
          image: mainImage,
          container,
          opacity: imageOpacity,
          position: imagePosition,
          superUpscaled: true,
        })}
      />
      <div className={`${s.imageBackgroundColor} ${c.evPageImageBackground}`} />
    </div>
  )
}
