import {
  getEventTitle,
  getPreliminaryInvoice,
  getTaxConfig,
  isAssignedTickets,
  isDateTbd,
} from '@wix/wix-events-commons-statics'
import {withTranslation} from 'react-i18next'
import {ReservationState} from '../../../../commons/enums'
import {DetailsPageAppProps} from '../../app/interfaces'
import {getCalendarLinks} from '../../../selectors/calendar-links'
import {connect} from '../../runtime-context/connect'
import {getFormButtonStyle} from '../../../selectors/settings'
import {isTemplate} from '../../../selectors/instance'
import {OrderSuccessRuntimeProps} from './interfaces'
import {OrderSuccess as Presentation} from './order-success'

const mapRuntime = ({
  state,
  actions: {ticketedThankYouPageLoaded, navigateToMainPage, addToCalendarClicked},
}: DetailsPageAppProps): OrderSuccessRuntimeProps => {
  const {
    event,
    component: {settings},
    checkout,
  } = state
  const template = isTemplate(state)
  const order = template
    ? {
        firstName: checkout.buyerDetails.firstName,
        email: checkout.buyerDetails.email,
        formattedCreatedDate: state.dates.common.nowYearMonthDayFormatted,
        invoice: getPreliminaryInvoice(state.tickets, state.selectedTickets, getTaxConfig(state.event)),
      }
    : state.placedOrder.order
  return {
    order,
    formattedOrderCreatedDate: state.dates.common.formattedOrderCreatedDate,
    eventTitle: getEventTitle(event),
    dateTbd: isDateTbd(event),
    formButtonStyle: getFormButtonStyle(settings),
    isAssignedTickets: isAssignedTickets(event),
    taxConfig: getTaxConfig(event),
    template,
    navigateToMainPage,
    calendarLinks: getCalendarLinks(state),
    orderPending: state.navigation.reservationState === ReservationState.PENDING,
    ticketedThankYouPageLoaded,
    addToCalendarClicked,
  }
}

export const OrderSuccess = connect<{}, OrderSuccessRuntimeProps>(mapRuntime)(withTranslation()(Presentation))
export * from './interfaces'
