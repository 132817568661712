import {getFormattedLocation} from '@wix/wix-events-commons-statics'
import React from 'react'
import classNames from 'classnames'
import {BrowserOnly} from '../../../../../commons/components/browser-only'
import {useEventDateInformation} from '../../../../../commons/hooks/dates'
import {DH} from '../../../../constants/data-hooks'
import cl from '../../../classnames.scss'
import {ShortDateLocationProps} from './interfaces'
import s from './short-date-location.scss'

const separator = '  |  '

export const ShortDateLocation = ({visible, event}: ShortDateLocationProps) => {
  const {shortStartDate} = useEventDateInformation(event.id)
  if (!visible) {
    return null
  }

  return (
    <div
      className={classNames(s.container, cl.evShortDateLocationFont, cl.evShortDateLocationColor)}
      data-hook={DH.headerShortDateLocation}
      role="text"
    >
      <span className={s.date} data-hook={DH.headerShortDate}>
        <BrowserOnly>{shortStartDate}</BrowserOnly>
      </span>
      <span className={s.separator}>{separator}</span>
      <span className={s.location} data-hook={DH.headerShortLocation}>
        {getFormattedLocation(event)}
      </span>
    </div>
  )
}
